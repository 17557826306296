.header {
    max-width: 570px;
    width: 100%;
    // min-height: 142px;
    background: #DE1C4A;
    padding: 20px 25px 35px 25px;
    min-height: 142px;
    @media( max-width: 500px ) {
        background-color: transparent;
        padding: 0;
    }
    h1 {
        margin: 0;
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 1; /* 75% */
        @media( max-width: 500px ) {
            padding: 0 25px;
            color: #000;
            line-height: 42.5px;
        }
    }
    .select-wrapper {
        // margin-bottom: 40px;
        margin-bottom: 31px;
        @media( max-width: 500px ) {
            margin-bottom: 18px;
            p {
                color: #000;
            }
        }
    }
}