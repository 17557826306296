.footer {
    max-width: 570px;
    width: 100%;
    background: #DE1C4A;
    // padding: 27px 25px 141px 25px;
    padding: 27px 25px 35px 25px;
    margin-top: 40px;
    @media( max-width: 500px ) {
        background: transparent;
        margin-top: 10px;
    }
    p {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        margin-bottom: 15px;
        @media( max-width: 500px ) {
            color: #000;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        strong {
            font-weight: 700;
        }
    }
}
