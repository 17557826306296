body, html {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.layout {
    display: flex;
    max-width: 1540px;
    padding: 0 30px;
    margin: 0 auto;
    @media( max-width: 500px ) {
        padding: 20px;
    }
}

strong {
    font-weight: 700 !important;
}

.fix {
    @media( min-width: 1131px ) {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }
    &.disabled-fix {
        @media( min-width: 1131px ) {
            position: relative;
            top: initial;
            transform: none;
        }
    }
}

.desktop {
    @media( max-width: 1130px ) {
        display: none;
    }
}

.mobile {
    @media( min-width: 1131px ) {
        display: none;
    }
}