.select-wrapper {
    @media( max-width: 500px ) {
        padding: 0 25px 13px 25px;
        border-bottom: 1px solid #DE1C4A;
    }
    p {
        margin: 0;
    }
    .selected {
        position: relative;
        display: flex;
        align-items: center;
        width: max-content;
        cursor: pointer;
        z-index: 9999;
        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
        &.active {
            .options {
                display: block;
            }
        }
        img {
            padding-left: 7px;
        }
        .options {
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            display: none;
            transition: .3s;
            padding: 5px;
            background-color: rgba(32, 57, 50, 0.9);
            border: 1px solid #fff;
            left: 1px;
            @media( max-width: 500px ) {
                background-color: #DE1C4A;
            }
            p {
                border-bottom: 1px solid #fff;
                padding: 5px 0;
                margin: 5px 0;
                line-height: 1;
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }
}