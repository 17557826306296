.layout {
    @media( max-width: 1130px ) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &>.col {
        @media( max-width: 1130px ) {
            @media( max-width: 1130px ) {
                max-width: 570px !important;
                min-width: initial !important;
            }
        }
    }
    &>.col {
        &:nth-of-type(1) {
            max-width: 340px;
            width: 100%;
            max-height: 615px;
            height: 100%;
            &.desktop {
                .image-wrapper {
                    min-width: 333px;
                    display: flex;
                    img {
                        margin: 0 auto;
                    }
                }
            }
            @media( max-width: 1280px ) {
                max-width: 250px;
                &.desktop {
                    .image-wrapper {
                        min-width: 250px;
                    }
                }
            }
        }
        &:nth-of-type(2) {
            max-width: 570px;
            min-width: 570px;
            width: 100%; 
            margin: 0 30px;
            @media( max-width: 1400px ) {
                max-width: 500px;
                min-width: 500px;
                margin: 0 20px;
            }
            @media( max-width: 1200px ) {
                max-width: 450px;
                min-width: 450px;
            }
        }
        &:nth-of-type(3) {
            max-width: 450px;
            width: 100%;
            display: flex;
            align-items: center;
            .fix {
                max-width: 450px;
                width: 100%;
                @media( max-width: 1440px ) {
                    max-width: 400px;
                }
                @media( max-width: 1160px ) {
                    max-width: 380px;
                }
                @media( max-width: 1130px ) {
                    max-width: 100%;
                }
            }
        } 
    }
}

.content-wrapper {
    margin: 20px 0;
    @media(min-width: 1131px) {
        min-height: 375px;
    }
    h2 {
        position: relative;
        margin: 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        padding-bottom: 23px;
        margin-bottom: 20px;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 90px;
            width: 100%;
            height: 2px;
            background-color: #DE1C4A;
        }
    }
    ul {
        margin: 10px 0 25px 0;
        list-style: none;
        padding-left: 25px;
        li {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-bottom: 15px;
            position: relative;
            @media( max-width: 600px ) {
                margin-bottom: 6px;
            }
            &:before {
                content:"";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: - 18px;
                width: 3px;
                height: 3px;
                background-color: #000;
                border-radius: 50%;
            }
        }
    }
    p,span {
        color: #000;
        font-size: 16px;
        line-height: 24px; /* 150% */
        margin: 12px 0 24px 0;
    }
}

.title {
    position: relative;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin: 0;
    padding-bottom: 10px !important;
    margin: 0;
    margin-bottom: 10px !important;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 450px;
        height: 1px;
        background: #00000080;
    }
}

.image-wrapper {
    // margin-top: 142px;
    &.mobile {
        text-align: center;
        margin: 24px 0 28px 0;
        padding-bottom: 24px;
        border-bottom: 1px solid #00000080;
        img {
            max-width: 149px;
            max-height: 275px;
        }
    } 
    &.avatar {
        img {
            max-width: 237px;
            max-height: 237px;
        }
    }
    img {
        max-width: 340px;
        max-height: 615px;
        @media( max-width: 1280px ) {
            max-width: 250px;
        }
        @media( max-width: 1200px ) {
            max-width: 200px;
        }
        
    }
}

.table-wrapper {
    // margin-top: calc(142px + 87px);
    max-width: 100%;
    @media( max-width: 1130px ) {
        max-width: 100%;
    }
    .table {
        .row {
            display: flex;
            border-bottom: 2px dotted #DE1C4A;
            margin: 10px 0;
            .col {
                color: #000;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
                margin: 5px;
                margin-top: 0;
                &:nth-of-type(1) {
                    text-align: left;
                    max-width: 60%;
                    width: 100%;
                    margin-left: 0;
                }
                &:nth-of-type(2), 
                &:nth-of-type(3) {
                    // max-width: 20%;
                    max-width: 40%;
                    width: 100%;
                }
                small {
                    display: block;
                    color: #000;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                }
            }
        }
    }
}

.icons-wrapper {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    margin-top: 25px;
    @media( max-width: 500px ) {
        border-bottom: 1px solid #DE1C4A;
    }
    .icon {
        margin: 15px;
        // max-width: calc(25% - 30px);
        width: max-content;
        @media( max-width: 1450px ) {
            margin: 10px 5px;
        }
        svg, img {
            max-width: 73px;
            max-height: 73x;
            @media( max-width: 500px ) {
                max-width: 60px;
                max-height: 60px;
            }
        }
        &.big {
            svg, img {
                max-width: 90px;
                max-height: 90px;
                @media( max-width: 500px ) {
                    max-width: 74px;
                    max-height: 74px;
                }
            }
        }
    }
}

.table-wrapper, .icons-wrapper {
    @media( min-width: 1131px ) {
        margin-right: 30px;
    }
}