.container {
    max-width: 660px;
    padding: 30px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    .title-wrapper {
        text-align: center;
        h1 {
            font-size: 44px;
            font-weight: normal;
            margin: 0;
            @media( max-width: 600px ) {
                font-size: 25.6px;
            }
            strong {
                display: block;
                font-weight: bold;
                color: #DC264D;
            }
        }
    }
    .qr {
        margin: 30px 0 60px 0;
        img {
            max-width: 300px;
            max-height: 300px;
            width: 100%;
            height: 100%;
        }
    }
    .footer-wrapper {
        text-align: center;
        p {
            position: relative;
            font-size: 16px;
            margin: 0;
            padding-top: 25px;
            @media( max-width: 600px ) {
                font-size: 11.9px;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100px;
                height: 2px;
                background-color: #DC264D;
            }
            a {
                text-decoration: underline;
                color: #000000
            }
        }
    }

}